
function calendarYearList() {
  let data = [
    { value: 2022, name: "Current School Year - 2021/2022", current: true },
    { value: 2021, name: "2020/2021" },
    { value: 2020, name: "2019/2020" },
    { value: 2019, name: "2018/19" },
    { value: 2018, name: "2017/18" }
  ];
  return data;
}

function termList() {
  return [
    { key: "autumn1", name: "Autumn 1" },
    { key: "autumn2", name: "Autumn 2" },
    { key: "spring1", name: "Spring 1" },
    { key: "spring2", name: "Spring 2" },
    { key: "summer1", name: "Summer 1" },
    { key: "summer2", name: "Summer 2" }
  ];
}

function filterTypeList() {
  return [
    { id: 1, value: "female", name: "Female" },
    { id: 2, value: "male", name: "Male" },
    { id: 3, value: "sen", name: "SEN" },
    { id: 4, value: "premium", name: "Pupil Premium" },
    { id: 5, value: "eal", name: "EAL" }
  ];
}

function courseTypeList(id) {
  let data = [
    { id: 1, value: "free" },
    { id: 2, value: "paid" },
    { id: 3, value: "school" },
    { id: 4, value: "organisation" },
    { id: 5, value: "community" },
    { id: 6, value: "pippaeddie" }
  ];

  return data.find(function (object) {
    return object.id === id;
  });
}

function layoutTypeList() {
  return [
    { key: "file", name: "Document" },
    { key: "textonly", name: "Text Only" },
    { key: "fields", name: "Form Fields with Main Document" },
    { key: "fieldscon", name: "Form Fields with Main Textbox" },
    { key: "empty", name: "Empty" }
  ];
}

function reportGridCalculation(type, list) {
  let registerLength = list.reduce(function (previous, current) {
    if (type == "male" || type == "female") {
      let registersByType = current.registers.filter(function (reg) {
        return reg.gender.toLowerCase() == type;
      });
      return previous + registersByType.length;
    } else if (type == "premium") {
      let registersByPP = current.registers.filter(function (reg) {
        return reg.premium_pupil.Int64 > 0;
      });
      return previous + registersByPP.length;
    } else if (type == "sen") {
      let registersBySEN = current.registers.filter(function (reg) {
        return reg.sen_status.String.toLowerCase().startsWith("sen");
      });
      return previous + registersBySEN.length;
    } else if (type == "eal") {
      let registersByPP = current.registers.filter(function (reg) {
        return reg.english_second.Int64 > 0;
      });
      return previous + registersByPP.length;
    } else {
      return previous + current.registers.length;
    }
  }, 0);

  return registerLength;
}

function reportGridYearCalculation(type, list, year) {
  let registerFilter = list.flatMap(function (activity) {
    return activity.registers.filter(reg => {
      return reg.yeargroup == year.value;
    });
  });

  var uniqueRegisters = registerFilter.reduce((unique, o) => {
    if (!unique.some(obj => obj.identifier === o.identifier)) {
      unique.push(o);
    }
    return unique;
  }, []);

  if (type == "male" || type == "female") {
    let registerByGender = uniqueRegisters.filter(reg => {
      return reg.gender.toLowerCase() == type;
    });
    return registerByGender.length;
  } else if (type == "premium") {
    let registersByPP = uniqueRegisters.filter(reg => {
      return reg.premium_pupil.Int64 > 0;
    });
    return registersByPP.length;
  } else if (type == "sen") {
    let registersBySEN = uniqueRegisters.filter(reg => {
      return reg.sen_status.String.toLowerCase().startsWith("sen");
    });
    return registersBySEN.length;
  } else if (type == "eal") {
    let registersByEAL = uniqueRegisters.filter(function (reg) {
      return reg.english_second.Int64 > 0;
    });
    return registersByEAL.length;
  } else {
    return uniqueRegisters.length;
  }
}

function colours() {
  return [
    { colour: "247,204,204" },
    { colour: "244,217,234" },
    { colour: "209,204,247" },
    { colour: "204,225,247" },
    { colour: "240,247,204" },
    { colour: "247,237,204" },
    { colour: "233,222,242" },
    { colour: "204,225,247" }
  ];
}
  
function timesframes() {
  return [
    { "title": "Breakfast", "time_value": "breakfast" },
    { "title": "Lunch", "time_value": "lunch" },
    { "title": "After School", "time_value": "after-school" }
  ]
}

function eventTypes() {
  return [
    { id: 1, "name": "PE Based", "value": "education", class: "bg-red-300" },
    { id: 2, "name": "Health based", "value": "health", class: "bg-green-300" },
    { id: 3, "name": "CPD", "value": "cpd", class: "bg-yellow-300" },
    { id: 4, "name": "Swimming", "value": "swimming", class: "bg-blue-300" },
    { id: 5, "name": "Other", "value": "other", class: "bg-gray-300" },
  ]
}

function eventLevels() {
  return [
    { id: 1, "name": "No Level", "value": 0 },
    { id: 2,"name": "Level One", "value": 1 },
    { id: 3,"name": "Level Two", "value": 2 },
    { id: 4, "name": "Level Three", "value": 3 },
    ]
}


function eventStandards() {
  return [
    { id: 1, "name": "No standard set", "value": "none" },
    { id: 2, "name": "First Team", "value": "firstTeam" },
    { id: 3, "name": "Second Team", "value": "secondTeam" },
    { id: 4, "name": "Third Team", "value": "thirdTeam" },
    { id: 5, "name": "Fourth Team", "value": "fourthTeam" },
    { id: 6, "name": "Other", "value": "other" },
  ]
}

export {
  termList,
  filterTypeList,
  layoutTypeList,
  calendarYearList,
  reportGridCalculation,
  reportGridYearCalculation,
  courseTypeList,
  colours,
  timesframes,
  eventTypes,
  eventLevels,
  eventStandards
};
