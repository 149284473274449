<template>
  <div name="eventview">
    <innertopbar @close="closeWindow()">
      <template slot="breadcrumb">
        <router-link to="events"> Events </router-link>
        <i class="carret fas fa-caret-right"></i> {{ title }}
      </template>

      <template slot="listitems">
        <li
          v-if="!newAddition"
          @click="deletion"
          title="Delete Event"
          class="action-list-item rounded-lg inline-block pt-2 bg-list-grey hover:opacity-100"
        >
          <i class="icon fas fa-trash-alt" title="Delete Event"></i>
           <p class="inline text-xs p-1">Delete</p>
        </li>

        <li
          v-if="!newAddition"
          class="inline-block mx-4 text-2xl text-gray-400"
        >
          |
        </li>

        <li class="inline-block">
          <button
            @click="submitForm()"
            type="button"
            class="publish-button hover:bg-opacity-75"
          >
            Publish Changes
          </button>
        </li>
      </template>
    </innertopbar>

    <div class="w-full mx-auto grid grid grid-flow-col grid-cols-2 gap-10">
      <div class="mt-6 ml-8 p-6 bg-white rounded-lg mb-10">
        <form
          class="w-95p mx-auto"
          v-on:submit.prevent="submitEvent"
          id="eventForm"
          ref="form"
        >
          <savenotice
            :title="'Pupil'"
            ref="saveNoticeTop"
            class="right"
          ></savenotice>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full">
              <label class="form-label" for="grid-title"> Event Title </label>
              <input
                v-model="name"
                class="mb-2 focus:border-red-200 focus:bg-white focus:outline-none"
                id="grid-title"
                type="text"
                placeholder="Enter event title"
              />
              <p class="text-red-300 text-xs italic">
                Please fill out this field.
              </p>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
              <label class="form-label" for="grid-title"> Start Date </label>
              <datetime
                type="datetime"
                v-model="startDate"
                input-class="date-input"
                :format="{
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  timeZoneName: 'short',
                }"
                :auto="true"
              ></datetime>
            </div>

            <div class="w-full md:w-1/2 pl-3">
              <label class="form-label" for="grid-title"> End Date </label>
              <datetime
                type="datetime"
                v-model="endDate"
                input-class="date-input"
                :format="{
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  timeZoneName: 'short',
                }"
                :auto="true"
              ></datetime>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
              <label class="form-label" for="grid-title"> Event Type </label>
              <dropdown
                :options="typeDropdown"
                :selected="selectedType"
                v-on:updateOption="typeOnSelect"
                class="bg-gray-100"
              ></dropdown>
            </div>

            <div class="w-full md:w-1/2 pl-3">
              <label class="form-label" for="grid-title"> Event Level </label>

              <dropdown
                :options="levelDropdown"
                :selected="selectedLevel"
                v-on:updateOption="levelOnSelect"
                class="bg-gray-100"
              ></dropdown>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 pr-3 mb-6 md:mb-0">
              <label class="form-label" for="grid-title">
                Event Standard
              </label>

              <dropdown
                :options="standardDropdown"
                :selected="selectedStandard"
                v-on:updateOption="standardOnSelect"
                class="bg-gray-100"
              ></dropdown>
            </div>

            <div class="w-full md:w-1/2 pl-3">
              <label class="form-label" for="grid-title">
                Event Duration (minutes)
              </label>
              <input
                v-model="duration"
                class="mb-2 text-center w-full bg-gray-100 h-10"
                id="grid-title"
                type="number"
                placeholder="Enter event duration"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full">
              <label class="form-label" for="grid-title"> Event Address </label>

              <input
                v-model="address"
                class="mb-2 focus:border-red-200 focus:bg-white focus:outline-none"
                type="text"
                placeholder="Enter event address"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-8">
            <div class="w-full">
              <label class="form-label" for="grid-title"> Event Details </label>

              <textarea
                v-model="details"
                cols="30"
                rows="10"
                class="p-2 focus:border-red-200 focus:bg-white focus:outline-none"
              ></textarea>
            </div>
          </div>

          <savenotice
            :title="'Pupil'"
            ref="saveNoticeBottom"
            class="right"
          ></savenotice>
        </form>
      </div>


      <div class="p-6 pt-10 bg-white">
        <div class="block h-1/2 overflow-y-auto">
          <h3 class="mb-6 ml-1 text-lg font-semibold">
            {{ pupilsSelected.length }} pupils at the event
            <button
              @click="showPupilGrid = true"
              type="button"
              class="
                action-button
                icon
                hover:bg-opacity-75
                fa-plus
                p-2
                py-1
                text-sm
                float-right
                mr-4
              "
            ></button>
          </h3>

                                <div
        style="width: 100%; text-align: center"
        class="spin-holder mt-4"
        v-if="loading"
      >
         <i class="spinner fa fa-spinner fa-spin"></i>
        <p>Loading Pupils</p>
      </div>

          <table class="table-auto w-full max-h-full" v-if="hasPupils">
            <thead class="bg-gray-100">
              <tr>
                <th class="w-7/12 text-left pl-2 py-1">Name</th>
                <th class="w-3/12 text-center pl-2">Year Group</th>
                <th class="w-2/12 text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="hover:bg-gray-300 cursor-pointer even:bg-gray-100"
                @click="selectPupil(pupil)"
                v-for="pupil in pupilsSelectedList"
                :key="pupil.identifier"
              >
                <td>{{ pupil.name }}</td>
                <td class="text-center"> {{pupilYearGroupDisplay(pupil)}}</td>
                <td class="text-center">
                  <i
                    class="fas fa-check text-base"
                    v-bind:class="selectedPupilStyle(pupil)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="m-5 mx-12" v-else>
            We could not find any data for your given selection.
          </p>
        </div>

        <div class="h-72 block" style="display:none;">
          <h3 class="mb-6 mt-4 ml-6 text-lg font-semibold">
            {{ pupils.length }} evidences for the event

            <button
              type="button"
              class="
                action-button
                icon
                hover:bg-blue-300
                fa-plus
                p-2
                py-1
                text-sm
                float-right
                mr-4
              "
            ></button>
          </h3>
        </div>
      </div>
    </div>

    <transition name="modalfile" v-if="showPupilGrid">
      <div
        class="modal-mask fixed top-0 left-0 w-full h-auto outline-none fade"
      >
        <div
          class="
            modal-container
            w-full
            mb-10
            mx-auto
            shadow-lg
            z-50
            h-full
            mt-6
          "
        >
          <div class="h-screen overflow-y-auto w-11/12 mx-auto rounded-xl">
            <pupilsselect
              :selectedPupils="pupilsSelectedList"
              @pupil-selected="selectPupil"
              :allowMultiple="true"
              :isInModal="true"
              @close="closePupilSelection"
              class="bg-gray-100 min-h-screen pb-20"
            ></pupilsselect>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const innertopbar = () => import("@/components/InnerTopBar.vue");
const savenotice = () => import("@/components/SaveNotice.vue");
const dropdown = () => import("@/components/Dropdown.vue");
const pupilsselect = () => import("@/components/PupilsGridSelect.vue");

import moment from "moment";
import { Datetime } from "vue-datetime";

import { mapGetters } from "vuex";
import { EventService } from "@/services/event.service";

import { eventTypes, eventLevels, eventStandards } from "@/assets/js/utils";
import { yearGroupMixin} from "@/mixins/yearGroupMixin.js";

export default {
  name: "eventview",
  mixins: [yearGroupMixin],
  props: {
    newAddition: { type: Boolean, required: true },
    event: { type: Object, required: true },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    isDisabled() {
      return false;
    },
    typeDropdown() {
      var results = [{ id: 0, name: "Select a type" }].concat(this.types);
      if (this.event.identifier === undefined) {
        this.selectedType = results[1];
      }
      return results;
    },
    levelDropdown() {
      var results = [{ id: 0, name: "Select a level" }].concat(this.levels);
      if (this.event.identifier === undefined) {
        this.selectedLevel = results[1];
      }
      return results;
    },
    standardDropdown() {
      var results = [{ id: 0, name: "Select a standard" }].concat(
        this.standards
      );
      if (this.event.identifier === undefined) {
        this.selectedStandard = results[1];
      }
      return results;
    },
    hasPupils() {
      return this.pupilsSelected.length > 0;
    },
    title() {
      if (this.newAddition) {
        return "Create";
      } else {
        return this.name;
      }
    },
    pupilsSelectedList() {
      return this.pupilsSelected;
    },
  },
  data() {
    return {
      school: this.$store.getters.school,
      eventInEdit: true,
      name: "",
      details: "",
      address: "",
      selectedType: {},
      selectedLevel: {},
      selectedStandard: {},
      startDate: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      endDate: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      duration: 45,
      pupils: [],
      pupilsSelected: [],
      pupilsRemoved: [],
      loading: false,
      errored: false,
      types: eventTypes(),
      levels: eventLevels(),
      standards: eventStandards(),
      showPupilGrid: false,
    };
  },
  created() {
    if (this.newAddition == false) {
      if (this.event == undefined) {
        this.closeWindow();
        return;
      } else {
        this.name = this.event.title == undefined ? "" : this.event.title;
        this.startDate = moment
          .utc(this.event.start_date)
          .format("YYYY-MM-DD[T]HH:mm:ss");
        this.endDate = moment
          .utc(this.event.end_date)
          .format("YYYY-MM-DDTHH:mm:ss");
        this.address = this.event.address.String;
        this.details = this.event.description.String;
        this.duration =
          this.event.duration.Valid === true ? this.event.duration.Int64 : 45;

        this.selectedType = this.types
          .filter((tt) => {
            return tt.value == this.event.program_type;
          })
          .shift();

        this.selectedLevel = this.levels
          .filter((tt) => {
            return tt.value == this.event.level;
          })
          .shift();

        if (this.event.standard.String === "") {
          this.selectedStandard = this.standards[0];
        } else {
          this.selectedStandard = this.standards
            .filter((tt) => {
              return tt.value == this.event.standard.String;
            })
            .shift();
        }

        this.fetchPupils();
      }
    }
  },
  mounted() {},
  methods: {
    fetchPupils() {
      this.loading = true;

      EventService.getPupils(this.event)
        .then((response) => (this.pupils = response.data))
        .catch((err) => {
          console.log("events pupils " + err);
          this.errored = true;
        })
        .finally(() => {
          this.pupilsSelected = this.pupils.slice();
          this.loading = false;
        });
    },
    pupilYearGroupDisplay(pupil) {
      let found = this.getYearGroupFromValue(pupil.yeargroup) 
      return found != undefined ? found.name : "Unknown"
    },
    submitForm() {
      if (this.$refs.form.requestSubmit === undefined) {
         this.processEvent()
      } else {
        this.$refs.form.requestSubmit();
      }
    },
    submitEvent() {
      if (this.name == "" || this.selectedType.id === 0) {
        this.alertDisplay();
      } else {
        this.processEvent();
      }
    },
    alertDisplay() {
      this.$swal({
        title: "Please complete all fields",
        text: "You must enter a title and set a class to complete the form.",
        type: "warning",
        showCancelButton: false,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result === true) {
          this.processPupil();
        }
      });
    },
    processEvent() {
      this.event.title = this.name;
      this.event.start_date = this.startDate;
      this.event.end_date = this.endDate;
      this.event.program_type = this.selectedType.value;
      this.event.level = this.selectedLevel.value;
      this.event.type_id = this.currentUser.typeID;

      this.event.address = {
        String: this.address,
        Valid: true,
      };

      this.event.description = {
        String: this.details,
        Valid: true,
      };

      this.event.duration = {
        Int64: parseInt(this.duration),
        Valid: true,
      };

      this.event.standard = {
        String: this.selectedStandard.value,
        Valid: true,
      };

      if (this.event.identifier === undefined) {
        EventService.add(this.event)
          .then((response) => {
            this.event.identifier = response.data.identifier;
            this.completion();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        EventService.update(this.event)
          .then((response) => this.completion(response.data))
          .catch((err) => {
            console.log(err);
          });
      }
    },
    completion(msg) {
      var vm = this;
      vm.pupilsRemoved.forEach((p) => {
        EventService.deletePupil(vm.event, p).catch((err) => {
          console.log(err);
        });
      });

      vm.pupilsSelected.forEach((p) => {
        let payload = {
          event_identifier: vm.event.identifier,
          register_identifier: p.identifier,
        };
        EventService.addPupil(payload).catch((err) => {
          console.log(err);
        });
      });

      this.$router.push({ name: "Events", params: { msg: msg } });
      window.scrollTo(0, 0);
    },
    failure(error) {
      this.$refs.saveNoticeTop.failed();
      this.$refs.saveNoticeBottom.failed();
      console.log("Event update error " + error);
    },
    typeOnSelect(value) {
      this.selectedType = value;
    },
    levelOnSelect(value) {
      this.selectedLevel = value;
    },
    standardOnSelect(value) {
      this.selectedStandard = value;
    },
    counterUpdated(value) {
      this.duration = value;
    },
    selectedPupilStyle(pupil) {
      return this.isSelected(pupil) ? "text-green-300" : "text-gray-200";
    },
    isSelected(value) {
      let found = this.pupilsSelected.find(
        (p) => p.identifier === value.identifier
      );
      return found;
    },
    selectPupil(pupil) {
      if (this.pupilsSelected.some((p) => p.identifier === pupil.identifier)) {
        this.pupilsSelected.splice(
          this.pupilsSelected.findIndex(
            (item) => item.identifier === pupil.identifier
          ),
          1
        );
        var toRemove = this.pupils.find(
          (p) => p.identifier === pupil.identifier
        );
        if (toRemove != undefined) {
          this.pupilsRemoved.push(pupil);
        }
      } else {
        this.pupilsSelected.push(pupil);
        var toRemove = this.pupilsRemoved.find(
          (p) => p.identifier === pupil.identifier
        );
        if (toRemove != undefined) {
          this.pupilsRemoved.splice(
            this.pupilsRemoved.findIndex(
              (item) => item.identifier === pupil.identifier,
              1
            )
          );
        }
      }
    },
    deletion() {
      this.$swal({
        title: "Are you sure you want to delete?",
        text: "You will not be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result) {
          this.processDelete();
        }
      });
    },
    processDelete() {
      EventService.delete(this.event)
        .then((response) => this.completion(response.data))
        .catch((err) => {
          console.log(err);
        });
    },
    closeWindow() {
      this.$router.push({ path: "events" });
      window.scrollTo(0, 0);
    },
    closePupilSelection() {
      this.showPupilGrid = false;
    },
  },
  components: {
    dropdown,
    savenotice,
    datetime: Datetime,
    innertopbar,
    pupilsselect,
  },
};
</script>


<style scoped>
</style>
