import { SchoolService } from "@/services/school.service"
import { mapGetters } from "vuex";

function yearList(type) {
  let data = [
    {
      id: 1,
      name: "Nursery",
      value: -1,
      level: "subscribed",
      order: 1,
      letter: "N",
      key_value: "nursery"
    },
    {
      id: 2,
      name: "Reception",
      value: 0,
      level: "subscribed",
      order: 2,
      letter: "R",
      key_value: "reception"
    },
    {
      id: 3,
      name: "Year One",
      value: 1,
      level: "subscribed",
      order: 3,
      letter: "1",
      key_value: "one"
    },
    {
      id: 4,
      name: "Year Two",
      value: 2,
      level: "subscribed",
      order: 4,
      letter: "2",
      key_value: "two"
    },
    {
      id: 5,
      name: "Year Three",
      value: 3,
      level: "subscribed",
      order: 5,
      letter: "3",
      key_value: "three"
    },
    {
      id: 6,
      name: "Year Four",
      value: 4,
      level: "subscribed",
      order: 6,
      letter: "4",
      key_value: "four"
    },
    {
      id: 7,
      name: "Year Five",
      value: 5,
      level: "subscribed",
      order: 7,
      letter: "5",
      key_value: "five"
    },
    {
      id: 8,
      name: "Year Six",
      value: 6,
      level: "subscribed",
      order: 8,
      letter: "6",
      key_value: "six"
    },
    {
      id: 9,
      name: "Year Seven",
      value: 7,
      level: "secondary",
      order: 9,
      letter: "7",
      key_value: "seven"
    },
    {
      id: 10,
      name: "Year Eight",
      value: 8,
      level: "secondary",
      order: 10,
      letter: "8",
      key_value: "eight"
    },
    {
      id: 11,
      name: "Year Nine",
      value: 9,
      level: "secondary",
      order: 11,
      letter: "9",
      key_value: "nine"
    },
    {
      id: 12,
      name: "Year Ten",
      value: 10,
      level: "secondary",
      order: 12,
      letter: "10",
      key_value: "ten"
    },
    {
      id: 13,
      name: "Year Eleven",
      value: 11,
      level: "secondary",
      order: 13,
      letter: "11",
      key_value: "eleven"
    },
    {
      id: 14,
      name: "Year Twelve",
      value: 12,
      level: "secondary",
      order: 14,
      letter: "12",
      key_value: "twelve"
    },
    {
      id: 15,
      name: "Year Thirteen",
      value: 13,
      level: "secondary",
      order: 14,
      letter: "13",
      key_value: "thirteen"
    },
    {
      id: 21,
      name: "Toddler",
      value: 13,
      level: "eyfs",
      order: -2,
      letter: "T"
    },
    {
      id: 22,
      name: "Pre-School",
      value: 14,
      level: "eysa",
      order: -1,
      letter: "PS"
    }
  ];

  return data.filter(function (year) {
    switch (type) {
      case "eyfs":
        return (year.id > 0 && year.id < 3) || year.level === type;
        break;
      case "international":
      case "organisation":
        return year.level === "subscribed" || year.level === "secondary";
        break;
      case "prep":
        return year.id > 4 && year.id < 12;
        break;
      case "infant":
        return year.id > 0 && year.id < 5;
        break;
      case "junior":
        return year.id > 0 && year.id < 7;
        break;
      case "trial":
        return year.id > 0 && year.id < 9;
        break;
      default:
        return year.level === type;
    }
  });
}

export const yearGroupMixin = {
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      settings: "settings"
    }),

  },
  created() {
    this.fetchYears();
  },
  data() {
    return {
      years: [],

    };
  },
  methods: {
    fetchYears() {
      this.years = yearList(this.currentUser.schoolType);

      this.years.forEach(y => {
        y.name = this.parsePlanningYearDisplay(y)
      })


    },
    parsePlanningYearDisplay(year) {
      let titles = this.settings.yearTitles

      if (titles === undefined || titles.length === 0) {
        return year.name
      } else {
        let found = titles.find(y => y.value === year.value)
        return found != undefined ? found.title : year.name
      }
    },
    getYearGroupFromValue(identifier) {
      return this.years.find(y => y.value == identifier)
    }

  }
}


